<template>
	<el-dialog v-dialogDrag :modal="true" append-to-body width="60vw" :title="'Distance from Center of ' + name"
		:visible="true" :close-on-click-modal="false" top="20vh" @close="close">
		<div class="content">
			<div class="dialog-content" ref="typhoon"></div>
		</div>
	</el-dialog>
</template>

<script>
var directionMap = {};
import * as echarts from 'echarts';
export default {
	props: {
		name: {
			type: String,
			default: 'xxx',
		},
		typhoonDataList: {
			type: Array,
			default: () => [
			],
		},
	},
	data() {
		return {
			nowTime: null,
		};
	},
	mounted() {
		this.nowTime = this.getTime()
		console.log(this.nowTime);
		this.$nextTick((e) => {
			this.addEcharts(this.typhoonDataList.filter((item) => this.isAfterNow(item.time)));
		});
	},
	methods: {
		isAfterNow(dateString) {
			// 获取当前年份
			let currentYear = new Date().getFullYear();
			// 解析输入时间串，设置为本地时间
			let inputDate = new Date(Date.parse(currentYear + "-" + dateString));
			// 比较时间并返回结果
			return inputDate.getTime() > this.nowTime.getTime();
		},
		getTime() {
			// 获取当前时间
			let now = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
			return now;
		},
		addEcharts(data) {
			let chart = echarts.init(this.$refs.typhoon);
			// 计算距离的最小值
			let minDistance = Math.floor(Math.min.apply(null, data.map((item) => item.distance)));
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				// 移除图例
				legend: {
					show: false,
				},
				grid: {
					top: 40,
					left: 50,
					right: 20,
					bottom: 20,
				},
				xAxis: {
					axisLine: {
						show: false, // 不显示坐标轴线
					},
					splitLine: {
						show: true, // 不显示坐标轴线
						lineStyle: {
							color: '#ddd',
						},
					},
					axisTick: {
						show: false,
					},
					data: data.map((item) => item.time),
				},
				yAxis: [
					{
						name: '',
						nameLocation: 'middle',
						// nameGap: 35,
						axisLine: {
							lineStyle: {
								color: '#666',
							},
						},
						splitLine: {
							lineStyle: {
								color: '#ddd',
							},
						},
						// 设置y轴的最小值
						min: minDistance,
					},
				],
				series: [
					{
						name: 'Distance',
						type: 'line',
						data: data.map((item) => item.distance),
						showSymbol: false,
						itemStyle: {
							color: '#2662D5', // 用您希望的默认颜色替换 'default_color'
						},
						z: 1,
					},
				],
			};
			console.log(option);
			chart.setOption(option);
		},
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less">
.content {
	width: 100%;

	.dialog-content {
		width: 100%;
		height: 190px;
		justify-content: space-between;
		align-items: flex-start;
	}
}
</style>
